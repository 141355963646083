import { render, staticRenderFns } from "./backlink.detail.vue?vue&type=template&id=62f739e0&"
import script from "./backlink.detail.vue?vue&type=script&lang=js&"
export * from "./backlink.detail.vue?vue&type=script&lang=js&"
import style0 from "./backlink.detail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\client\\Admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62f739e0')) {
      api.createRecord('62f739e0', component.options)
    } else {
      api.reload('62f739e0', component.options)
    }
    module.hot.accept("./backlink.detail.vue?vue&type=template&id=62f739e0&", function () {
      api.rerender('62f739e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/backlinks/backlink.detail.vue"
export default component.exports
import vueFormGenerator from 'vue-form-generator';
import serviceEnums from '@/services/service/enums.service';
import { formatter } from '@/services/helpers/formatter';

export const model = {
	BacklinkId: 0,
	ObjectId: null,
	BacklinkCreationDate: null,
	BacklinkCountryId: null,
	BacklinkContactName: '',
	BacklinkContactEmail: '',
	BacklinkContactPhone: '',
	BacklinkWebLink: '',
	BacklinkHtmlCode: '',
	BacklinkUrlLink1: '',
	BacklinkAnchorText1: '',
	BacklinkUrlLink2: '',
	BacklinkAnchorText2: '',
	BacklinkUrlLink3: '',
	BacklinkAnchorText3: '',
	BacklinkUrlLink4: '',
	BacklinkAnchorText4: '',
	BacklinkUrlLink5: '',
	BacklinkAnchorText5: '',
	BacklinkUrlLink6: '',
	BacklinkAnchorText6: '',
	BacklinkBonus: '',
	BacklinkExpirationDate: null,
	BacklinkCategory: null,
	BacklinkType: null,
	BacklinkState: null,
	BacklinkLastCheckDate: null,
	BacklinkNote: '',
	ObjectLink: '',
	ObjectName: '',
	ObjectValidTo: null,
	EmailExists: false,
};

export const fields = [
	{
		fields: [
			{
				type: 'single-datepicker',
				model: 'BacklinkCreationDate',
				required: false,
				autoselect: true,
				help: '',
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'dateadded2841',
				},
			},
		],
	},
	{
		styleClasses: 'inline-input-group-wrapper-detail',
		fields: [
			{
				type: 'autocomplete',
				model: 'ObjectId',
				typeSearch: '6',
				validator: vueFormGenerator.validators.integer,
				styleClasses: 'first-input',
				i18n: {
					label: 'assignedobject',
				},
			},
			{
				type: 'buttonlink',
				icon: 'eye',
				model: 'ObjectLink',
				styleClasses: 'second-input',
				visible: function(model) {
					return model.ObjectLink && model.ObjectLink.Href;
				},
				i18n: {
					label: '',
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'label',
				model: 'ObjectValidTo',
				get: function(model) {
					return model && model.ObjectValidTo ? formatter.dateToString(model.ObjectValidTo) : '';
				},
				i18n: {
					label: 'objectvalidunti2881',
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'select',
				model: 'BacklinkCountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
		],
	},
	{
		styleClasses: 'col-xs-12 inline-input-group',
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkContactName',
				styleClasses: 'half-width',
				i18n: {
					label: 'contactperson',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkContactEmail',
				styleClasses: 'half-width',
				i18n: {
					label: 'contactemail',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkContactPhone',
				styleClasses: 'half-width',
				i18n: {
					label: 'contactphone',
				},
			},
		],
	},
	{
		styleClasses: 'inline-input-group-wrapper-detail',
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkWebLink',
				styleClasses: 'first-input',
				i18n: {
					label: 'addedtothesite2846',
				},
			},
			{
				type: 'buttonlink',
				icon: 'eye',
				model: 'BacklinkWebLinkUrl',
				styleClasses: 'second-input',
				visible: function(model) {
					return model.BacklinkWebLinkUrl && model.BacklinkWebLinkUrl.Href;
				},
				i18n: {
					label: '',
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'textArea',
				id: 'html-code',
				model: 'BacklinkHtmlCode',
				min: 1,
				rows: 6,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'htmlcode',
				},
			},
		],
	},
	{
		styleClasses: 'inline-input-group-wrapper-detail',
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkUrlLink1',
				styleClasses: 'first-input',
				i18n: {
					label: 'urllink',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkAnchorText1',
				styleClasses: 'second-input',
				i18n: {
					label: 'anchortext',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkUrlLink2',
				styleClasses: 'first-input',
				i18n: {
					label: 'urllink2849',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkAnchorText2',
				styleClasses: 'second-input',
				i18n: {
					label: 'anchortext',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkUrlLink3',
				styleClasses: 'first-input',
				i18n: {
					label: 'urllink2850',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkAnchorText3',
				styleClasses: 'second-input',
				i18n: {
					label: 'anchortext',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkUrlLink4',
				styleClasses: 'first-input',
				i18n: {
					label: 'urllink2851',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkAnchorText4',
				styleClasses: 'second-input',
				i18n: {
					label: 'anchortext',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkUrlLink5',
				styleClasses: 'first-input',
				i18n: {
					label: 'urllink2852',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkAnchorText5',
				styleClasses: 'second-input',
				i18n: {
					label: 'anchortext',
				},
			},
		],
	},
	{
		styleClasses: 'inline-input-group-wrapper-detail',
		fields: [
			{
				type: 'input',
				inputType: 'text',
				model: 'BacklinkBonus',
				styleClasses: 'first-input',
				i18n: {
					label: 'agreedbonus',
				},
			},
			{
				type: 'buttonlink',
				icon: 'cogs',
				model: 'ObjectConfigLink',
				styleClasses: 'second-input ObjectConfigLink',
				visible: function(model) {
					return model.ObjectConfigLink && model.ObjectConfigLink.Href;
				},
				i18n: {
					label: '',
				},
			},
		],
	},
	{
		fields: [
			{
				type: 'single-datepicker',
				model: 'BacklinkExpirationDate',
				required: false,
				autoselect: true,
				help: '',
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'validto2856',
				},
			},
			{
				type: 'select',
				model: 'BacklinkCategory',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('backlinkCategory'),
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'category',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'BacklinkType',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('backlinkType'),
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'type:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'select',
				model: 'BacklinkState',
				validator: vueFormGenerator.validators.integer,
				values: serviceEnums.getEnumForVFG('backlinkState'),
				selectOptions: {
					noneSelectedText: '',
				},
				styleClasses: 'half-width',
				i18n: {
					label: 'status2866',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
			},
			{
				type: 'single-datepicker',
				model: 'BacklinkLastCheckDate',
				required: false,
				autoselect: true,
				help: '',
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'lastchecked',
				},
			},
			{
				type: 'textArea',
				model: 'BacklinkNote',
				min: 1,
				rows: 4,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'remark:',
				},
			},
		],
	},
];

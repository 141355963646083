<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:headerTitle="headerTitle"
		tooltipMsg="manualforbackli3307"
		:showActionDelete="showDeleteButton"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #beforeForm>
			<b-tooltip target="html-code" triggers="hover">
				<div v-html="model.BacklinkHtmlCode" />
			</b-tooltip>
			<remark
			v-if="detailEntityId"
			:entityId="detailEntityId"
			:backmethod="redirectToDetail"
			:entityTypeId="remarkEntityType"
			:remarkTypeId="null"
			:administratorList="administratorList"
			></remark>
			<email-send-dropdown
				v-if="detailEntityId"
				:labelText="$t('e-mails:')"
				:pesLogTypeId="pesLogTypeId"
				:buttonText="$t(`fornewyear'sev`)"
				:offersList="offersList"
				:entityId="detailEntityId"
				:emailExist="model.EmailExists"
				:withTable="true"
			></email-send-dropdown>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceCommon from '@/services/service/common.service';
import enums from '@/services/helpers/enums';
import http from '@/services/helpers/http';
import apiUser from '@/services/api/user.api';
import servicePortalEmail from '@/modules/portal-emails/portal-email.service';

import { model, fields } from './backlink.form-data';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Backlink',
			routeParam: 'Id',
			model,
			schema: {
				groups: fields,
			},
			remarkEntityType: enums.remarkEntityType.Backlink,
			administratorList: [],
			pesLogTypeId: enums.sendLogsTypeEnum.Backlink,
			offersList: [],
		};
	},

	computed: {
		headerTitle() {
			const title = this.$t('detailofbacklin');
			const id = this.$t('id');
			const idNum = this.model.BacklinkId;
			return `${title} ${id} ${idNum}`;
		},

		showDeleteButton() {
			return !!this.detailEntityId;
		},
	},

	async created() {
		await Promise.all([this.getAdministrators(), this.getEmailSubjects()]);
	},

	methods: {
		async loadResources() {
			this.updateSchemaFields({
				BacklinkCountryId: {
					values: this.$store.state.country.data,
				},
				ObjectId: {
					onChanged: async (model) => {
						this.updateFormModel(await this.onObjectChanged(model));
					},
				},
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.AcompanyId, this.$modal, this.deleteModel);
		},

		async onObjectChanged(model) {
			const modelToUpdate = {
				BacklinkCountryId: null,
				BacklinkContactName: null,
				BacklinkContactEmail: null,
				BacklinkContactPhone: null,
				BacklinkWebLink: null,
			};

			if (!model.ObjectId) {
				return modelToUpdate;
			}

			const [detail, exists] = await Promise.all([
				http.get('Object/GetDetailById', { id: this.model.ObjectId }).then((res) => res.data),
				http.get('Backlink/CheckExistBacklinkByObject', { objectId: this.model.ObjectId }).then((res) => res.data),
			]);

			if (detail) {
				modelToUpdate.BacklinkCountryId = detail.ObjectCountryId;
				modelToUpdate.BacklinkContactName = detail.ContactPerson;
				modelToUpdate.BacklinkContactEmail = detail.Email;
				modelToUpdate.BacklinkContactPhone = detail.Phone;
				modelToUpdate.BacklinkWebLink = detail.Web;
			}

			if (exists) {
				this.$store.dispatch('alert/warning', this.$t('theselectedobje'));
			}

			return modelToUpdate;
		},

		transformDtoToModel(dtoModel) {
			dtoModel.ObjectLink = {
				Name: dtoModel.ObjectName + ' ' + this.$i18n.t('id') + ' ' + dtoModel.ObjectId,
				Href: dtoModel.ObjectLink,
			};

			dtoModel.BacklinkWebLinkUrl = {
				Name: dtoModel.Urls,
				Href: dtoModel.Urls,
			};

			dtoModel.ObjectConfigLink = {
				Name: this.$i18n.t('settings') + ' ' + this.$i18n.t('id') + ' ' + dtoModel.ObjectId,
				Href: '/object/' + dtoModel.ObjectId,
			};

			return dtoModel;
		},

		transformModelToDto(model) {
			model.ObjectLink = null;
			model.BacklinkWebLinkUrl = null;
			model.ObjectConfigLink = null;
			return model;
		},

		async getAdministrators() {
			const admins = await apiUser.getAdministratorList().then((res) => res.data);
			admins.unshift({ id: null, name: this.$i18n.t('unassigned') });
			this.administratorList = admins;
		},

		async getEmailSubjects() {
			const emailIds = '64,65,66,67';
			this.offersList = await servicePortalEmail.getEmailSubjectsByIds(emailIds).then((res) => res.data);
		},
	},
};
</script>

<style lang="scss">
.vue-form-generator .inline-input-group-wrapper-detail {
	.form-group.error {
		position: relative;
		.errors {
			position: absolute;
			left: 100%;
			width: fit-content;
		}
	}
	@include ig-first-item(43%, 65%, 92%);
	@include ig-second-item(42%, 10px);
}
.backlink-form {
	.field-wrap {
		.megauButton {
			> button {
				color: #20a8d8;
				background-color: transparent;
				border-color: transparent;
			}
		}
	}
}
.backlink-form {
	.ObjectConfigLink {
		.field-wrap {
			.megauButton {
				> button {
					color: #28a745;
					background-color: transparent;
					border-color: transparent;
				}
			}
		}
	}
}
</style>

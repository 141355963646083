var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          headerTitle: _vm.headerTitle,
          tooltipMsg: "manualforbackli3307",
          showActionDelete: _vm.showDeleteButton,
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
          delete: function ($event) {
            return _vm.openDeleteModal()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "beforeForm",
              fn: function () {
                return [
                  _c(
                    "b-tooltip",
                    { attrs: { target: "html-code", triggers: "hover" } },
                    [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.model.BacklinkHtmlCode),
                        },
                      }),
                    ]
                  ),
                  _vm.detailEntityId
                    ? _c("remark", {
                        attrs: {
                          entityId: _vm.detailEntityId,
                          backmethod: _vm.redirectToDetail,
                          entityTypeId: _vm.remarkEntityType,
                          remarkTypeId: null,
                          administratorList: _vm.administratorList,
                        },
                      })
                    : _vm._e(),
                  _vm.detailEntityId
                    ? _c("email-send-dropdown", {
                        attrs: {
                          labelText: _vm.$t("e-mails:"),
                          pesLogTypeId: _vm.pesLogTypeId,
                          buttonText: _vm.$t("fornewyear'sev"),
                          offersList: _vm.offersList,
                          entityId: _vm.detailEntityId,
                          emailExist: _vm.model.EmailExists,
                          withTable: true,
                        },
                      })
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          870406171
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }